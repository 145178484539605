<script setup>
import LanguageSwitching from '~/components/LanguageSwitching.vue'
import HireRegistrationForm from '~/components/register/HireRegistrationForm.vue'
import SignUpSearchInput from '~/components/register/SignUpSearchInput.vue'
import { CONFIG_BANNER_HIRE, SQUAD_03_20241002_COMPANY_REGISTRATION_FIELD_ADJUSTMENTS } from '~/config/featureFlags'

const { $hasFeature, $config } = useNuxtApp()

// get from local storage the variant to show
const variant = localStorage.getItem('variant') || 'A'
</script>

<template>
	<div v-if="$hasFeature(SQUAD_03_20241002_COMPANY_REGISTRATION_FIELD_ADJUSTMENTS) && variant !== 'B'">
		<div class="bg-neutral-50 bg-opacity-90 flex flex-col flex-1 min-h-screen relative">
			<div class="hidden lg:block absolute inset-0 w-1/2 bg-white h-full"></div>
			<div class="hidden lg:block absolute inset-0 left-1/2 w-1/2 bg-neutral-50 h-full"></div>
			<div class="w-full container mx-auto flex flex-col relative z-10 max-w-[1560px]">
				<div class="flex w-full bg-white lg:bg-transparent p-4 lg:p-0 items-center justify-between mx-auto">
					<div class="w-full lg:w-1/2 bg-white p-4 lg:p-8 flex items-center">
						<NuxtLinkLocale
							to="/"
							class="flex mr-3 items-center justify-center"
						>
							<ULogo
								type="hire"
								class="w-36"
							/>
						</NuxtLinkLocale>
					</div>

					<div class="hidden lg:flex lg:w-1/2 p-4 lg:p-8 justify-end items-center">
						<div class="text-sm text-gray-700">
							<LanguageSwitching />
						</div>
					</div>

					<div class="flex lg:hidden justify-end items-center">
						<div class="text-sm text-gray-700">
							<LanguageSwitching />
						</div>
					</div>
				</div>

				<div class="flex flex-col lg:flex-row flex-1 w-full mx-auto">
					<div class="w-full lg:w-1/2 bg-white p-8 flex flex-col h-auto">
						<HireRegistrationForm />
					</div>

					<div class="w-full lg:w-1/2 p-8 flex flex-col items-center justify-center">
						<p class="text-xl text-center font-bold">
							{{ $t('Recibe en minutos recomendaciones de talento con stacks pre-validados para tu oferta.') }}
						</p>

						<div
							class="z-10 relative inset-0 bg-no-repeat bg-right lg:bg-[length:80%]"
							:style="{
								'background-image': `url(${$config.public.cdn}/map-light_3_1710191441030.webp)`,
								'background-position': 'top right',
							}"
						>
							<div class="flex items-center my-7 w-full">
								<SignUpSearchInput class="mx-auto" />
							</div>

							<img
								class="w-full mx-auto"
								src="https://cdn.talently.tech/talently-match_1725480766059.webp"
								alt="talently match view"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		v-else
		class="relative"
	>
		<div class="bg-neutral-50 bg-opacity-90 flex flex-col flex-1 min-h-screen">
			<LazyUInfoBanner
				v-if="$hasFeature(CONFIG_BANNER_HIRE)"
				:config="$feature.getFeatureValue(CONFIG_BANNER_HIRE)"
			/>

			<div class="flex-shrink-0 top-0 z-50">
				<template v-if="variant === 'B'">
					<header
						id="hire-navbar"
						class="w-full bg-white xl:bg-transparent"
					>
						<UContainer class="!py-5">
							<div class="flex justify-end items-center gap-3">
								<LanguageSwitching />
							</div>
						</UContainer>
					</header>
				</template>
				<template v-else>
					<header
						id="hire-navbar"
						class="bg-neutral-50 lg:bg-white w-full"
					>
						<UContainer class="!py-5">
							<div class="flex justify-between items-center gap-3">
								<NuxtLinkLocale
									to="/"
									class="flex mr-3 items-center justify-center"
								>
									<ULogo
										type="hire"
										class="w-36"
									/>
								</NuxtLinkLocale>
								<LanguageSwitching />
							</div>
						</UContainer>
					</header>
				</template>
			</div>

			<main class="flex-1 flex flex-col">
				<slot />
			</main>
		</div>
	</div>
</template>
